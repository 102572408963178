import request from '@/utils/request'

export function getList(categoryId) {
  return request({
    url: '/newsInformation/list/' + categoryId,
    method: 'get'
  })
}

export function show(infoId) {
    return request({
      url: '/newsInformation/info/' + infoId,
      method: 'get'
    })
  }