<template>
  <Tab :list="tabList" :current="current" />
  <div class="flex-1 fff flex-col">
    <van-nav-bar
      title=""
      left-text="返回"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="flex-1 detail">
      <h2 class="title">{{ detail.infoTitle }}</h2>
      <p class="time">{{ detail.publishTime }}</p>
      <div class="content" v-html="detail.infoContent"></div>
    </div>
  </div>
</template>

<script>
import { newsTabList } from '@/utils/tab'
import { useRoute, useRouter } from 'vue-router'
import { reactive, ref } from '@vue/reactivity'
import Tab from '@/components/tab/index.vue'
import { show } from '@/api/news'

export default {
  components: {
    Tab,
  },
  setup() {
    const router = useRouter()
    const onClickLeft = () => {
      router.back()
    }

    const route = useRoute()
    const category = route.query.category || '8'
    let current = ref(0)
    switch (category) {
      case '9':
        current.value = 1
        break
      case '10':
        current.value = 2
        break

      default:
        current.value = 0
        break
    }

    const tabList = reactive(newsTabList)

    const detail = ref({})
    show(route.query.id).then(res => {
      detail.value = res.result
    })

//     const detail = reactive({
//       title: '新研究称去年底美国已有近三分之一人口感染新冠',
//       time: '2021-09-09 20:26:27',
//       html: `<section class="main-text-container" style="height: initial; overflow: hidden;"><p style="text-indent: 2em;">
// 	新华社华盛顿9月9日电 美国研究人员新近根据模型计算发现，美国的新冠实际感染人数远超官方统计。早在2020年年底，美国可能就已有近三分之一的人口感染新冠病毒。
// </p> <p style="text-indent: 2em;">
// 	美国哥伦比亚大学研究人员日前在英国《自然》杂志网络版发表论文说，他们使用数据驱动的模型推断方法，以县域为单位模拟了美国的疫情传播情况。为了进一步验证模拟结果的准确性，研究人员还将模型计算获得的部分数据与美国疾病控制和预防中心实施的血清抽样调查结果进行比对，发现两种数据相吻合，从而可以详细估计全美精确到县域的新冠感染率。
// </p> <p style="text-indent: 2em;">
// 	研究人员利用模型研究发现，2020年年底，全美新冠病毒易感人群占比约为69%。易感人群通常是指对某种传染病缺乏特异性免疫力而容易被感染的人群。对新冠病毒而言，人群普遍易感，各年龄段人群均对新冠病毒没有免疫力。感染过新冠病毒或接种新冠疫苗之后人体会产生抗体，就会对新冠病毒产生一定免疫力。由于美国是从2020年12月中旬才开始接种新冠疫苗的，69%的易感人群比例就意味着其余近三分之一的美国人口当时已感染新冠病毒。
// </p> <p style="text-indent: 2em;">
// 	此外，研究人员还发现，2020年全年美国新冠感染查明率（官方确诊病例数占实际总感染病例数的比例）平均值为21.8%。这也就是说，2020年全年美国估计有78.2%的新冠感染病例没有被官方确诊并记录。
// </p> <p style="text-indent: 2em;">
// 	据美国约翰斯·霍普金斯大学统计数据，截至2020年年底，美国累计报告的新冠确诊病例约1994万例。按照上述查明率推算，去年年底美国累计新冠感染人数实际高达9100多万。
// </p> <p style="text-indent: 2em;">
// 	编辑：王宇轩
// </p></section>`,
//     })
    return {
      onClickLeft,
      tabList,
      current,
      detail,
    }
  },
}
</script>

<style lang="less" scoped>
.detail {
  padding: 16px;
  .title {
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }
  .time {
    text-align: center;
    color: #666;
    font-size: 14px;
  }
  .content {
    border-top: 1px solid #e5e5e5;
  }
}
</style>
